import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { 
  GiGreekTemple, 
  GiCrownOfThorns, 
  GiCastle,
  GiSpellBook,
  GiDna1,
  GiCrossMark,
  GiHistogram,
  GiScrollUnfurled,
  GiOpenBook,
  GiHouseKeys,
  GiQuillInk,
  GiExitDoor
} from 'react-icons/gi';
import { RiUserSettingsLine, RiUserLine } from 'react-icons/ri';
import { IoHelpCircleOutline } from 'react-icons/io5';
import styled from 'styled-components';

// Import class images
import burningSeedImage from '../assets/images/subclasses/Burning/circleoftheburningseed.png';
import dronewrightImage from '../assets/images/subclasses/Dronewright/dronewright.jpg';
import glacialHeartImage from '../assets/images/subclasses/Glacial/circleoftheglacialheart.jpg';
import pathOfWrathImage from '../assets/images/subclasses/Wrath/pathofwrath.png';
import pyromancerImage from '../assets/images/subclasses/Pyromancer/pyromancer.png';
import seraphicGuardianImage from '../assets/images/subclasses/Seraphic/oathoftheseraphicguardian.png';
import sovereignZealotImage from '../assets/images/subclasses/Sovereign/sovereignzealot.jpg';
import umbralInfiltratorImage from '../assets/images/subclasses/Umbral/umbralinfiltrator.png';
import lifedrinkerImage from '../assets/images/subclasses/Lifedrinker/wayofthelifedrinker.jpg';
import winterbornKnightImage from '../assets/images/subclasses/Winterborn/winterbornknight.jpg';

// Import species images
import gravewalkerImage from '../assets/images/races/Gravewalker/gravewalker.jpg';
import humanityImage from '../assets/images/races/Humanity/humanity.jpg';
import lifedrinkerSpeciesImage from '../assets/images/races/Lifedrinker/lifedrinker.jpg';
import manaforgedImage from '../assets/images/races/Manaforged/manaforged.png';
import scytheraiImage from '../assets/images/races/Scytherai/scytherai.png';
import seraphiendImage from '../assets/images/races/Seraphiend/seraphiend.png';
import skinwalkerImage from '../assets/images/races/SkinWalker/skinwalker.jpg';
import starbornImage from '../assets/images/races/Starborn/starborn.png';
import sunseedImage from '../assets/images/races/Sunseed/sunseed.jpg';
import vermisCorpusImage from '../assets/images/races/Vermis Corpus/vermiscorpus.jpg';
import winterWitchImage from '../assets/images/races/Winter Witch/winterwitch.jpg';

// Import pantheon images
import caelumbrisPantheonImage from '../assets/images/deities/Caelumbris.png';
import calantharPantheonImage from '../assets/images/deities/Calanthar.png';
import leviathanPantheonImage from '../assets/images/deities/leviathan.png';
import firstbornPantheonImage from '../assets/images/deities/firstborn.jpg';
import librisPantheonImage from '../assets/images/deities/libris.jpg';
import nebulaPantheonImage from '../assets/images/deities/Nebula.jpg';
import queenMabPantheonImage from '../assets/images/deities/queenmab.png';
import queenTitaniaPantheonImage from '../assets/images/deities/queentitania.png';
import sacrathorPantheonImage from '../assets/images/deities/Sacrathor.jpg';
import valdisPantheonImage from '../assets/images/deities/valdis.png';
import wildscarPantheonImage from '../assets/images/deities/wildscar.png';

// Import Veiled Paths (deity) images
import caelumbrisVeiledImage from '../assets/images/deities/mirror.jpg';
import firstbornVeiledImage from '../assets/images/deities/firstbornsymbol.jpg';
import librisVeiledImage from '../assets/images/deities/libris.jpg';
import nebulaVeiledImage from '../assets/images/deities/redmist.jpg';
import queenMabVeiledImage from '../assets/images/deities/crownofice.jpg';
import queenTitaniaVeiledImage from '../assets/images/deities/sunburstwildgrowth.jpg';
import sacrathorVeiledImage from '../assets/images/deities/coiledsnakesun.jpg';
import unboundVeiledImage from '../assets/images/deities/unbound.jpg';
import valdisVeiledImage from '../assets/images/deities/hornshalo.jpg';
import wildscarVeiledImage from '../assets/images/deities/bloodaxe.jpg';

// Fixed top navigation bar
const NavBar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to bottom, #1a1a1a, #0d0d0d);
  border-bottom: 1px solid rgba(193, 154, 107, 0.3);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
  z-index: 998;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
`;

// Navigation button
const NavButton = styled.button`
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${props => props.isActive ? '#ffd700' : '#fff'};
  transition: all 0.2s ease;
  cursor: pointer;
  
  &:hover {
    color: #ffd700;
  }
  
  .fantasy-icon {
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
  }
`;

// Button label
const ButtonLabel = styled.span`
  font-size: 0.8rem;
  margin-top: 4px;
  font-family: 'Cinzel', serif;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
`;

// Fullscreen menu overlay
const MenuOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.95);
  z-index: 999;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
  transform: ${props => props.isOpen ? 'translateY(0)' : 'translateY(-100%)'};
  overflow-y: auto;
`;

// Menu header
const MenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid rgba(193, 154, 107, 0.3);
  background: linear-gradient(to bottom, #1a1a1a, #0d0d0d);
  position: sticky;
  top: 0;
`;

// Menu title
const MenuTitle = styled.h2`
  margin: 0;
  color: #ffd700;
  font-size: 1.8rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Cinzel', serif;
`;

// Close button
const CloseButton = styled.button`
  background: transparent;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: #ffd700;
  }
`;

// Menu content
const MenuContent = styled.div`
  padding: 30px 20px;
  flex: 1;
`;

// Enhanced Menu List
const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  max-width: 600px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 15px;
`;

// Enhanced Menu Item
const MenuItem = styled.li`
  margin-bottom: 0;
  
  a {
    display: flex;
    align-items: center;
    padding: 20px;
    background: rgba(193, 154, 107, 0.1);
    border-radius: 8px;
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease;
    font-size: 1.3rem;
    font-family: 'Cinzel', serif;
    
    &:hover {
      background: rgba(193, 154, 107, 0.3);
      color: #ffd700;
      transform: translateY(-3px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
    
    .menu-icon {
      margin-right: 15px;
      font-size: 1.5rem;
      color: #b39a68;
    }
    
    &:hover .menu-icon {
      color: #ffd700;
    }
  }
`;

// Add thumbnail grid container
const ThumbnailGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 900px;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

// Thumbnail item
const ThumbnailItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  background: rgba(193, 154, 107, 0.1);
  border-radius: 8px;
  padding: 15px;
  transition: all 0.2s ease;
  
  &:hover {
    background: rgba(193, 154, 107, 0.2);
    transform: translateY(-5px);
  }
`;

// Thumbnail image
const ThumbnailImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 10px;
  border: 1px solid rgba(193, 154, 107, 0.3);
`;

// Thumbnail name
const ThumbnailName = styled.span`
  color: #fff;
  font-size: 1rem;
  text-align: center;
  font-family: 'Cinzel', serif;
  
  ${ThumbnailItem}:hover & {
    color: #ffd700;
  }
`;

const Navigation = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();
  
  // Check if user has sufficient role
  const hasAccess = currentUser && 
    (currentUser.role === 'adventurer' || currentUser.role === 'admin');
  
  const handleMenuToggle = (menuName) => {
    setActiveMenu(activeMenu === menuName ? null : menuName);
  };
  
  const handleNavigation = (path) => {
    if (!hasAccess && path.startsWith('/deity')) {
      alert("You need to be an Adventurer or Admin to access this content.");
      return;
    }
    
    navigate(path);
    setActiveMenu(null);
  };

  const handleLogout = () => {
    logout();
    setActiveMenu(null);
  };
  
  // Define menu data
  const menuData = {
    firstVeil: {
      title: "First Veil",
      icon: <GiCastle size={24} className="fantasy-icon" />,
      paths: [
        { name: "Profile", path: "/dashboard/profile", icon: <RiUserLine size={22} className="menu-icon" /> },
        { name: "History", path: "/history", icon: <GiHistogram size={22} className="menu-icon" /> },
        { name: "House Rules", path: "/house-rules", icon: <GiScrollUnfurled size={22} className="menu-icon" /> },
        { name: "Prologue", path: "/prologue", icon: <GiOpenBook size={22} className="menu-icon" /> },
        { name: "Help", path: "/help", icon: <IoHelpCircleOutline size={22} className="menu-icon" /> },
        { name: "Account", path: "/dashboard/account", icon: <RiUserSettingsLine size={22} className="menu-icon" /> },
        ...(currentUser ? [{ name: "Logout", onClick: handleLogout, icon: <GiExitDoor size={22} className="menu-icon" /> }] : [])
      ]
    },
    classes: {
      title: "Classes",
      icon: <GiSpellBook size={24} className="fantasy-icon" />,
      paths: [
        { 
          name: "Circle of the Burning Seed", 
          path: "/classes/ClassesBurningSeed",
          image: burningSeedImage
        },
        { 
          name: "Dronewright", 
          path: "/classes/ClassesDronewright",
          image: dronewrightImage
        },
        { 
          name: "Circle of the Glacial Heart", 
          path: "/classes/ClassesGlacialHeart",
          image: glacialHeartImage
        },
        { 
          name: "Path of Wrath", 
          path: "/classes/ClassesPathOfWrath",
          image: pathOfWrathImage
        },
        { 
          name: "Pyromancer", 
          path: "/classes/ClassesPyromancer",
          image: pyromancerImage
        },
        { 
          name: "Seraphic Guardian", 
          path: "/classes/ClassesSeraphicGuardian",
          image: seraphicGuardianImage
        },
        { 
          name: "Sovereign Zealot", 
          path: "/classes/ClassesSovereignZealot",
          image: sovereignZealotImage
        },
        { 
          name: "Umbral Infiltrator", 
          path: "/classes/ClassesUmbralInfiltrator",
          image: umbralInfiltratorImage
        },
        { 
          name: "Way of the Lifedrinker", 
          path: "/classes/ClassesWayOfTheLifedrinker",
          image: lifedrinkerImage
        },
        { 
          name: "Winterborn Knight", 
          path: "/classes/ClassesWinterbornKnight",
          image: winterbornKnightImage
        }
      ]
    },
    species: {
      title: "Species",
      icon: <GiDna1 size={24} className="fantasy-icon" />,
      paths: [
        { 
          name: "Gravewalker", 
          path: "/species/SpeciesGravewalker",
          image: gravewalkerImage
        },
        { 
          name: "Humanity", 
          path: "/species/SpeciesHumanity",
          image: humanityImage
        },
        { 
          name: "Lifedrinker", 
          path: "/species/SpeciesLifedrinker",
          image: lifedrinkerSpeciesImage
        },
        { 
          name: "Manaforged", 
          path: "/species/SpeciesManaforged",
          image: manaforgedImage
        },
        { 
          name: "Scytherai", 
          path: "/species/SpeciesScytherai",
          image: scytheraiImage
        },
        { 
          name: "Seraphiend", 
          path: "/species/SpeciesSeraphiend",
          image: seraphiendImage
        },
        { 
          name: "Skinwalker", 
          path: "/species/SpeciesSkinWalker",
          image: skinwalkerImage
        },
        { 
          name: "Starborn", 
          path: "/species/SpeciesStarborn",
          image: starbornImage
        },
        { 
          name: "Sunseed", 
          path: "/species/SpeciesSunseed",
          image: sunseedImage
        },
        { 
          name: "Vermis Corpus", 
          path: "/species/SpeciesVermisCorpus",
          image: vermisCorpusImage
        },
        { 
          name: "Winter Witch", 
          path: "/species/SpeciesWinterWitch",
          image: winterWitchImage
        }
      ]
    },
    deity: {
      title: "Veiled Paths",
      icon: <GiCrownOfThorns size={24} className="fantasy-icon" />,
      paths: [
        { 
          name: "Caelumbris Shadowed", 
          path: "/deity/caelumbris-shadowed",
          image: caelumbrisVeiledImage
        },
        { 
          name: "Libris Knowledge", 
          path: "/deity/libris-knowledge",
          image: librisVeiledImage
        },
        { 
          name: "Firstborn Soulbound", 
          path: "/deity/firstborn-soulbound",
          image: firstbornVeiledImage
        },
        { 
          name: "Nebula Mist Walker", 
          path: "/deity/nebula-mist-walker",
          image: nebulaVeiledImage
        },
        { 
          name: "Queen Mab Frozen Veil", 
          path: "/deity/queen-mab-frozen-veil",
          image: queenMabVeiledImage
        },
        { 
          name: "Queen Titania Wildgrowth", 
          path: "/deity/queen-titania-wildgrowth",
          image: queenTitaniaVeiledImage
        },
        { 
          name: "Sacrathor Dominion", 
          path: "/deity/sacrathor-dominion",
          image: sacrathorVeiledImage
        },
        { 
          name: "Unbound", 
          path: "/deity/unbound",
          image: unboundVeiledImage
        },
        { 
          name: "Valdis Justice", 
          path: "/deity/valdis-justice",
          image: valdisVeiledImage
        },
        { 
          name: "Wildscar Ember Fury", 
          path: "/deity/wildscar-ember-fury",
          image: wildscarVeiledImage
        }
      ]
    },
    pantheon: {
      title: "Pantheons",
      icon: <GiGreekTemple size={24} className="fantasy-icon" />,
      paths: [
        { 
          name: "Caelumbris", 
          path: "/pantheon/caelumbris",
          image: caelumbrisPantheonImage
        },
        { 
          name: "Calanthar", 
          path: "/pantheon/calanthar",
          image: calantharPantheonImage
        },
        { 
          name: "Leviathan", 
          path: "/pantheon/leviathan",
          image: leviathanPantheonImage
        },
        { 
          name: "Firstborn", 
          path: "/pantheon/firstborn",
          image: firstbornPantheonImage
        },
        { 
          name: "Libris", 
          path: "/pantheon/libris",
          image: librisPantheonImage
        },
        { 
          name: "Nebula", 
          path: "/pantheon/nebula",
          image: nebulaPantheonImage
        },
        { 
          name: "Queen Mab", 
          path: "/pantheon/queen-mab",
          image: queenMabPantheonImage
        },
        { 
          name: "Queen Titania", 
          path: "/pantheon/queen-titania",
          image: queenTitaniaPantheonImage
        },
        { 
          name: "Sacrathor", 
          path: "/pantheon/sacrathor",
          image: sacrathorPantheonImage
        },
        { 
          name: "Valdis", 
          path: "/pantheon/valdis",
          image: valdisPantheonImage
        },
        { 
          name: "Wildscar", 
          path: "/pantheon/wildscar",
          image: wildscarPantheonImage
        }
      ]
    }
  };
  
  return (
    <>
      {/* Navigation bar with icons */}
      <NavBar>
        <NavButton 
          isActive={activeMenu === 'firstVeil'}
          onClick={() => handleMenuToggle('firstVeil')}
        >
          {menuData.firstVeil.icon}
          <ButtonLabel>First Veil</ButtonLabel>
        </NavButton>
        
        <NavButton 
          isActive={activeMenu === 'classes'}
          onClick={() => handleMenuToggle('classes')}
        >
          {menuData.classes.icon}
          <ButtonLabel>Classes</ButtonLabel>
        </NavButton>
        
        <NavButton 
          isActive={activeMenu === 'species'}
          onClick={() => handleMenuToggle('species')}
        >
          {menuData.species.icon}
          <ButtonLabel>Species</ButtonLabel>
        </NavButton>
        
        {hasAccess && (
          <>
            <NavButton 
              isActive={activeMenu === 'deity'}
              onClick={() => handleMenuToggle('deity')}
            >
              {menuData.deity.icon}
              <ButtonLabel>Veiled Paths</ButtonLabel>
            </NavButton>
            
            <NavButton 
              isActive={activeMenu === 'pantheon'}
              onClick={() => handleMenuToggle('pantheon')}
            >
              {menuData.pantheon.icon}
              <ButtonLabel>Pantheons</ButtonLabel>
            </NavButton>
          </>
        )}
      </NavBar>
      
      {/* Menu overlay for each section */}
      {Object.keys(menuData).map(key => (
        hasAccess || (key !== 'deity' && key !== 'pantheon') ? (
          <MenuOverlay key={key} isOpen={activeMenu === key}>
            <MenuHeader>
              <MenuTitle>{menuData[key].title}</MenuTitle>
              <CloseButton onClick={() => setActiveMenu(null)}>
                <GiCrossMark />
              </CloseButton>
            </MenuHeader>
            
            <MenuContent>
              {key === 'classes' || key === 'species' || key === 'pantheon' || key === 'deity' ? (
                <ThumbnailGrid>
                  {menuData[key].paths.map((item, index) => (
                    <ThumbnailItem key={index} onClick={() => item.onClick ? item.onClick() : handleNavigation(item.path)}>
                      <ThumbnailImage src={item.image} alt={item.name} />
                      <ThumbnailName>{item.name}</ThumbnailName>
                    </ThumbnailItem>
                  ))}
                </ThumbnailGrid>
              ) : (
                <MenuList>
                  {menuData[key].paths.map((item, index) => (
                    <MenuItem key={index}>
                      <a onClick={() => item.onClick ? item.onClick() : handleNavigation(item.path)}>
                        {item.icon}
                        <span>{item.name}</span>
                      </a>
                    </MenuItem>
                  ))}
                </MenuList>
              )}
            </MenuContent>
          </MenuOverlay>
        ) : null
      ))}
    </>
  );
};

export default Navigation; 